import styled from 'styled-components'

export const StyledHomePageStorylineImage = styled.img`
	width: 100%;
	height: 53rem;
	object-fit: cover;
	@media (min-width: ${p => p.theme.breakpoints.tablet}) {
		height: 60rem;
	}
	@media (min-width: ${p => p.theme.breakpoints.desktop}) {
		height: 55rem;
	}
`
