import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import { Link } from 'react-router-dom'
import { useIsVisible, useWindowDimensions } from '@/hooks'
import { SvgMobileNavClose, SvgMobileNavHamburger } from '@/assets/svg'
import { SiteLogo } from '@/app/components'
import { NavListDecorator } from '@/styles/common/component'
import { Wrapper } from '@/styles/common/layout'
import {
	PrimaryHeaderWrapper,
	HeaderLogoWrapper,
	PrimaryNavigationWrapper,
	PrimaryNavList,
	NavItem,
	DesktopNavList,
	DesktopNavItem,
	HamBurgerButton,
	FloatingHamBurgerButton,
	ContactButton,
} from './styles'
import { breakpoints } from '@/constants'

const PrimaryHeader = () => {
	const [navListOpen, setNavListOpen] = useState(false)
	const primaryNavRef = React.useRef<HTMLDivElement | null>(null)
	const isPrimaryNavVisible = useIsVisible(primaryNavRef)
	const dimensions = useWindowDimensions()
	const isDesktopWidth = dimensions.width >= breakpoints.desktop
	const handleNavListOpen = () => {
		setNavListOpen(prev => !prev)
	}
	const handleNavItemClick = () => {
		setNavListOpen(false)
	}

	useEffect(() => {
		const stopResizeAnimation = () => {
			document.body.classList.add('resize-animation-stopper')
			const timer = setTimeout(() => {
				document.body.classList.remove('resize-animation-stopper')
			}, 0)
			return () => clearTimeout(timer)
		}

		window.addEventListener('resize', stopResizeAnimation)
		return () => {
			window.removeEventListener('resize', stopResizeAnimation)
		}
	}, [])

	useEffect(() => {
		document.body.style.overflow = navListOpen ? 'hidden' : 'initial'
		return () => {
			document.body.style.overflow = 'initial'
		}
	}, [navListOpen])

	return (
		<PrimaryHeaderWrapper ref={primaryNavRef}>
			<Wrapper>
				<Flex justify="space-between" align="center">
					<Link to="/" onClick={handleNavItemClick}>
						<HeaderLogoWrapper>
							<SiteLogo />
							<span>Quibit</span>
						</HeaderLogoWrapper>
					</Link>

					<HamBurgerButton onClick={handleNavListOpen}>
						{navListOpen ? (
							<SvgMobileNavClose />
						) : (
							<SvgMobileNavHamburger />
						)}
					</HamBurgerButton>

					{!isPrimaryNavVisible && (
						<FloatingHamBurgerButton
							$navListOpen={navListOpen}
							onClick={handleNavListOpen}>
							{navListOpen ? (
								<SvgMobileNavClose />
							) : (
								<SvgMobileNavHamburger />
							)}
						</FloatingHamBurgerButton>
					)}

					{/* Desktop Nav List Start */}
					<DesktopNavList
						data-visible={navListOpen}
						aria-hidden={
							isDesktopWidth ? (navListOpen ? false : true) : true
						}>
						<NavListDecorator $desktopNavListOpen={navListOpen}>
							<span>navigation</span>
						</NavListDecorator>
						<div>
							<li>
								<DesktopNavItem
									to="/"
									onClick={handleNavItemClick}>
									Home
								</DesktopNavItem>
							</li>
							<li>
								<DesktopNavItem
									to="/about"
									onClick={handleNavItemClick}>
									About
								</DesktopNavItem>
							</li>
							<li>
								<DesktopNavItem
									to="/products"
									onClick={handleNavItemClick}>
									Products
								</DesktopNavItem>
							</li>
							<li>
								<DesktopNavItem
									to="/learn"
									onClick={handleNavItemClick}>
									Learn
								</DesktopNavItem>
							</li>
							<li>
								<DesktopNavItem
									to="/benefits"
									onClick={handleNavItemClick}>
									Benefits
								</DesktopNavItem>
							</li>
							<li>
								<DesktopNavItem
									to="/contact"
									onClick={handleNavItemClick}>
									Contact Us
								</DesktopNavItem>
							</li>
						</div>
					</DesktopNavList>
					{/* Desktop Nav List End */}

					<PrimaryNavigationWrapper data-visible={navListOpen}>
						<NavListDecorator className="sm-only">
							<span>navigation</span>
						</NavListDecorator>
						<PrimaryNavList aria-label="Primary" role="list">
							<li className="sm-only">
								<NavItem to="/" onClick={handleNavItemClick}>
									Home
								</NavItem>
							</li>
							<li>
								<NavItem
									to="/about"
									onClick={handleNavItemClick}>
									About
								</NavItem>
							</li>
							<li>
								<NavItem
									to="/products"
									onClick={handleNavItemClick}>
									Products
								</NavItem>
							</li>
							<li>
								<NavItem
									to="/learn"
									onClick={handleNavItemClick}>
									Learn
								</NavItem>
							</li>
							<li>
								<NavItem
									to="/benefits"
									onClick={handleNavItemClick}>
									Benefits
								</NavItem>
							</li>
							<li className="sm-only">
								<NavItem
									to="/contact"
									onClick={handleNavItemClick}>
									Contact Us
								</NavItem>
							</li>
						</PrimaryNavList>
					</PrimaryNavigationWrapper>

					<Link className="lg-only" to="/contact">
						<ContactButton>Contact Us</ContactButton>
					</Link>
				</Flex>
			</Wrapper>
		</PrimaryHeaderWrapper>
	)
}

export default PrimaryHeader
