import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SiteLogo } from "@/app/components";
import { FlowSpacer, Wrapper } from "@/styles/common/layout";
import {
  FooterBottom,
  FooterLogoWrapper,
  FooterNavList,
  FooterNavWrapper,
  PrimaryFooterWrapper,
} from "./styles";

const PrimaryFooter = () => {
  return (
    <PrimaryFooterWrapper>
      <Wrapper>
        <FooterNavWrapper>
          <Link to="/" className="navlink">
            <FooterLogoWrapper>
              <SiteLogo />
              <span>Quibit</span>
            </FooterLogoWrapper>
          </Link>
          <FooterNavList aria-label="Primary" role="list">
            <li>
              <Link to="/about" className="navlink">
                About
              </Link>
            </li>
            <li>
              <NavLink to="/products" className="navlink">
                Products
              </NavLink>
            </li>
            <li>
              <NavLink to="/learn" className="navlink">
                Learn
              </NavLink>
            </li>
            <li>
              <NavLink to="/benefits" className="navlink">
                Benefits
              </NavLink>
            </li>
          </FooterNavList>
          <FooterBottom>
            <FlowSpacer $mobile="1rem" $tablet="1rem" $desktop="1rem">
              <span className="remember">
                Remember we’re always with you 🤗!
              </span>
              <p className="copyright">© 2024 Quibit. All rights reserved.</p>
            </FlowSpacer>
          </FooterBottom>
        </FooterNavWrapper>
      </Wrapper>
    </PrimaryFooterWrapper>
  );
};

export default PrimaryFooter;
