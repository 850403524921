import React from "react";

const SvgMobileNavClose = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      {...props}
    >
      <rect x="0.5" y="0.5" width="51" height="51" rx="25.5" fill="white" />
      <rect x="0.5" y="0.5" width="51" height="51" rx="25.5" stroke="#1C1C1C" />
      <path
        d="M20 20L31.3137 31.3137"
        stroke="#646464"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 31L31.3137 19.6863"
        stroke="#646464"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgMobileNavClose;
