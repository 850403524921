import styled from "styled-components";

export const AskQuibCardsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  & .prodcuts-askquib-card-image {
    min-height: 100%;
  }
  & .products-askquib-card-paragraph {
    padding-block: 1rem;
    padding-inline: 1rem;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    & .products-askquib-card {
      grid-template-columns: 1fr 1fr;
      gap: 0;
    }
    & .products-askquib-card:nth-of-type(1) .products-askquib-card-paragraph {
      order: -1;
    }
    & .products-askquib-card:nth-of-type(3) .products-askquib-card-paragraph {
      order: -1;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
    & .products-askquib-card {
      height: 36rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    & .products-askquib-card:nth-of-type(1) p {
      order: initial;
    }
    & .products-askquib-card:nth-of-type(3) p {
      order: initial;
    }
  }
`;
