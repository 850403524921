import { DISPLAY, TEXT } from "@/constants";
import { BaseButton } from "@/styles/common/component";
import styled from "styled-components";

export const ProductPageProductHeading = styled.h2`
  color: ${(props) => props.theme.colors.neutralB900};
  font-size: ${DISPLAY.XS.MEDIUM.fontSize};
  font-weight: ${DISPLAY.XS.MEDIUM.fontWeight};
  line-height: ${DISPLAY.XS.MEDIUM.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.MD.SEMI_BOLD.fontSize};
    font-weight: ${DISPLAY.MD.SEMI_BOLD.fontWeight};
    line-height: ${DISPLAY.MD.SEMI_BOLD.lineHeight};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    text-align: center;
  }
`;

export const ProductPageProductParagraph = styled.p`
  color: ${(props) => props.theme.colors.neutralB500};
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 65ch;
    margin-inline: auto;
    text-align: center;
  }
`;

export const MobileExpandCardsButton = styled(BaseButton)`
  background: initial;
  border: none;
  margin-inline: auto;
`;

export const MobileExpandCardsText = styled.p`
  max-width: 12ch;
  margin-inline: auto;
  text-align: center;
  color: #000;
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
`;
