import styled from "styled-components";
import { DISPLAY, TEXT } from "@/constants";

export const FormGroup = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  & > .form-input {
    grid-column: 1 / -1;
  }
  & > .form-error {
    align-self: flex-end;
    grid-row: 1;
    grid-column: 2;
    text-align: end;
    font-size: ${TEXT.SM.REGULAR.fontSize};
    font-weight: ${TEXT.SM.REGULAR.fontWeight};
    line-height: ${TEXT.SM.REGULAR.lineHeight};
    color: #ea323f;
  }
`;

export const BaseInput = styled.input`
  padding-block: 0.875rem;
  padding-inline: 0.5rem;
  border: 0;
  border-radius: 0.875rem;
  background: ${(p) => p.theme.colors.neutralW300};
  font: inherit;

  &[data-error="true"] {
    outline: 2px solid #ea323f;
  }
  &::placeholder {
    font-size: ${TEXT.MD.REGULAR.fontSize};
    font-weight: ${TEXT.MD.REGULAR.fontWeight};
    line-height: ${TEXT.MD.REGULAR.lineHeight};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-block: 0.875rem;
    padding-inline: 1rem;
    font-size: ${TEXT.LG.REGULAR.fontSize};
    font-weight: ${TEXT.LG.REGULAR.fontWeight};
    line-height: ${TEXT.LG.REGULAR.lineHeight};
  }
`;

export const BaseLabel = styled.label`
  font: inherit;
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};

  &[data-error="true"] {
    outline: 2px solid #ea323f;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.XS.MEDIUM.fontSize};
    font-weight: ${DISPLAY.XS.MEDIUM.fontWeight};
    line-height: ${DISPLAY.XS.MEDIUM.lineHeight};
  }
`;

export const BaseTextarea = styled.textarea`
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  border-radius: 1rem;
  border: 0;
  background: ${(p) => p.theme.colors.neutralW300};
  font: inherit;

  &[data-error="true"] {
    outline: 2px solid #ea323f;
  }

  &::placeholder {
    font-size: ${TEXT.MD.REGULAR.fontSize};
    font-weight: ${TEXT.MD.REGULAR.fontWeight};
    line-height: ${TEXT.MD.REGULAR.lineHeight};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-block: 0.875rem;
    padding-inline: 1rem;
    font-size: ${TEXT.LG.REGULAR.fontSize};
    font-weight: ${TEXT.LG.REGULAR.fontWeight};
    line-height: ${TEXT.LG.REGULAR.lineHeight};
  }
`;
