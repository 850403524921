import React from "react";
import {
  HomePage,
  AboutPage,
  ProductPage,
  LearnPage,
  BenefitPage,
  ContactPage,
} from "./LazyPages";

const mainRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/about", element: <AboutPage /> },
  { path: "/products", element: <ProductPage /> },
  { path: "/learn", element: <LearnPage /> },
  { path: "/benefits", element: <BenefitPage /> },
  { path: "/contact", element: <ContactPage /> },
];

export default mainRoutes;
