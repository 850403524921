import React from "react";
import Routers from "@/app/routes";
import { themeColor, themeBreakpoints } from "@/styles/theme";
import isPropValid from "@emotion/is-prop-valid";
import { useSelector } from "react-redux";
import {
  ThemeProvider as StyledThemeProvider,
  StyleSheetManager,
} from "styled-components";
import { ConfigProvider } from "antd";
import GlobalStyle from "./styles/GlobalStyle";

function App() {
  const darkMode = useSelector((state: any) => state?.appstore?.darkMode);
  const selectedThemeColor = darkMode ? themeColor?.dark : themeColor?.light;
  const theme = { colors: selectedThemeColor, breakpoints: themeBreakpoints };
  return (
    <ConfigProvider>
      <StyledThemeProvider theme={theme}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <GlobalStyle />
          <Routers />
        </StyleSheetManager>
      </StyledThemeProvider>
    </ConfigProvider>
  );
}

function shouldForwardProp(propName: any, target: any) {
  if (typeof target === "string") {
    return isPropValid(propName);
  }
  return true;
}

export default App;
