import React from "react";
import { Helmet } from "react-helmet-async";

const PageWrapper = ({
  title,
  children,
  style = {},
}: {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Quibit - {title} </title>
      </Helmet>
      <main style={style}>{children}</main>
    </React.Fragment>
  );
};

export default PageWrapper;
