import React from "react";

const HomePage = React.lazy(() => import("../pages/HomePage"));
const AboutPage = React.lazy(() => import("../pages/AboutPage"));
const ProductPage = React.lazy(() => import("../pages/ProductPage"));
const LearnPage = React.lazy(() => import("../pages/LearnPage"));
const BenefitPage = React.lazy(() => import("../pages/BenefitPage"));
const ContactPage = React.lazy(() => import("../pages/ContactPage"));

export {
  HomePage,
  AboutPage,
  ProductPage,
  LearnPage,
  BenefitPage,
  ContactPage,
};
