import React from "react";

const SiteLogo = () => {
  return (
    <img
      width={38}
      height={42}
      className="site-logo"
      src="/quibit-logo.png"
      alt="Quibit Logo"
    />
  );
};

export default SiteLogo;
