import styled from "styled-components";

export const AboutPageCardsLayout = styled.div`
  display: grid;
  gap: 4rem;
  & .about-page-card-image {
    min-height: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 6rem;
    & .about-page-card {
      grid-auto-flow: column;
      grid-auto-columns: 1fr 1fr;
      & .about-page-card-info {
        align-self: center;
        padding-block: 1rem;
        order: -1;
        & .about-page-card-info {
          padding-block: 1rem;
        }
      }
    }
    & .about-page-card:last-child {
      grid-auto-flow: row;
      grid-auto-columns: initial;
      grid-auto-rows: 5fr 3fr;
      & .about-page-card-info {
        order: initial;
      }
    }
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;
    & .about-page-card {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    & .about-page-card:last-child {
      grid-auto-flow: row;
      grid-column-start: 3;
      grid-column-end: -1;
      grid-row-start: 1;
      grid-row-end: 3;
      & .about-page-card-info {
        order: 1;
      }
    }
  }
`;
