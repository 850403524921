import styled from "styled-components";
import { TEXT } from "@/constants";

export const PrimaryFooterWrapper = styled.footer`
  margin-top: 2rem;
  padding-block: 2rem;
  padding-inline: 0;
  background: ${(props) => props.theme.colors.neutralB700};
  border-radius: 1rem 1rem 0 0;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {

    padding-block: 4.5rem;
    padding-inline: 1rem;
    border-radius: 2.5rem 2.5rem 0 0;
  }
`;

export const FooterNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 2rem;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
  }
  & .navlink {
    text-decoration: none;
  }
`;

export const FooterLogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: ${(p) => p.theme.colors.neutralW700};
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const FooterNavList = styled.ul`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  list-style: none;
  & .navlink {
    text-decoration: none;
    color: ${(p) => p.theme.colors.neutralB50};
    font-size: ${TEXT.SM.MEDIUM.fontSize};
    font-weight: ${TEXT.SM.MEDIUM.fontWeight};
    line-height: ${TEXT.SM.MEDIUM.lineHeight};
  }

  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 2.5rem;
    & .navlink {
      font-size: ${TEXT.MD.MEDIUM.fontSize};
      font-weight: ${TEXT.MD.MEDIUM.fontWeight};
      line-height: ${TEXT.MD.MEDIUM.lineHeight};
    }
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${TEXT.SM.MEDIUM.fontSize};
  font-weight: ${TEXT.SM.MEDIUM.fontWeight};
  line-height: ${TEXT.SM.MEDIUM.lineHeight};
  & .remember {
    color: ${(props) => props.theme.colors.neutralW700};
  }
  & .copyright {
    color: ${(props) => props.theme.colors.neutralB50};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
    font-size: ${TEXT.MD.MEDIUM.fontSize};
    font-weight: ${TEXT.MD.MEDIUM.fontWeight};
    line-height: ${TEXT.MD.MEDIUM.lineHeight};
    & .copyright {
      text-align: center;
    }
  }
`;
