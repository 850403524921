import { DISPLAY, TEXT } from "@/constants";
import { BaseBox } from "@/styles/common/component";
import styled from "styled-components";

export const HomePageRealTimeContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 2.5rem;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const HomePageRealTimeContentItem = styled(BaseBox)<{
  $bgColor: string;
}>`
  padding-block: 2rem;
  padding-inline: 1.75rem;
  display: grid;
  text-align: center;
  place-content: center;
  gap: 1rem;
  color: #000;
  background-color: ${(p) => p.$bgColor};
  border-radius: 1.5rem;
  border: 2px solid ${(p) => p.theme.colors.neutralB700};
  & svg {
    margin-inline: auto;
  }
  & p {
    font-size: ${DISPLAY.MD.BOLD.fontSize};
    font-weight: ${DISPLAY.MD.BOLD.fontWeight};
    line-height: ${DISPLAY.MD.BOLD.lineHeight};
    letter-spacing: ${DISPLAY.MD.BOLD.letterSpacing};
  }
  & span {
    color: ${(p) => p.theme.colors.neutralB700};
    font-size: ${TEXT.SM.REGULAR.fontSize};
    line-height: ${TEXT.SM.REGULAR.lineHeight};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    & p {
      font-size: ${DISPLAY.LG.BOLD.fontSize};
      font-weight: ${DISPLAY.LG.BOLD.fontWeight};
      line-height: ${DISPLAY.LG.BOLD.lineHeight};
      letter-spacing: ${DISPLAY.LG.BOLD.letterSpacing};
    }
    & span {
      font-size: ${TEXT.XL.REGULAR.fontSize};
      line-height: ${TEXT.XL.REGULAR.lineHeight};
    }
  }
`;
