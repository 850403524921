import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkMode: false,
};

export const appStore = createSlice({
  name: "appstore",
  initialState: initialState,
  reducers: {
    switchMode(state, { payload }) {
      state.darkMode = payload;
    },
    
  },
});
export const { switchMode} = appStore.actions;

export default appStore.reducer;
