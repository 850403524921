import styled from "styled-components";
import { DISPLAY, TEXT } from "@/constants";

export const PseudoContent = styled.div<{
  $mobile?: string;
  $tablet?: string;
  $desktop?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  width: 100%;
  height: ${(p) => (p.$mobile ? p.$mobile : "100%")};
  display: grid;
  place-content: center;
  background: ${(p) => p.$bgColor || "#f1f1f1"};
  color: #000;
  font-size: ${TEXT.XL.REGULAR.fontSize};
  font-weight: ${TEXT.XL.REGULAR.fontWeight};
  line-height: ${TEXT.XL.REGULAR.lineHeight};
  border-radius: ${(p) => p.$borderRadius || 0};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: ${(p) => (p.$tablet ? p.$tablet : "100%")};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: ${(p) => (p.$desktop ? p.$desktop : "100%")};
  }
`;

export const BaseButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  text-decoration: none;
  background: ${(p) => p.theme.colors.primary700};
  border: 2px solid ${(p) => p.theme.colors.neutralB700};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${(p) => p.theme.colors.neutralW700};
  }
  &:focus-visible {
    outline: 2px solid ${(p) => p.theme.colors.primary500};
  }
`;

export const BaseBox = styled.div`
  border-radius: 2rem;
  border: 2px solid #000;
  background: #fff;
  box-shadow: 6px 6px 0px 0px #000;
`;

export const BaseCard = styled.article<{ $noPadding?: boolean; $gap?: string }>`
  padding-block: ${(p) => (p.$noPadding ? 0 : "1rem")};
  padding-inline: ${(p) => (p.$noPadding ? 0 : "1rem")};
  display: grid;
  align-items: center;
  gap: ${(p) => (p.$gap ? p.$gap : "1.5rem")};
  border-radius: 1.5rem;
  border: 2px solid #000;
  background: #f1f1f1;
  box-shadow: 4px 4px 0px 0px #000;
  overflow: hidden;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-block: ${(p) => (p.$noPadding ? 0 : "1.5rem")};
    padding-inline: ${(p) => (p.$noPadding ? 0 : "1.5rem")};
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const BaseTag = styled.div<{ $bgBold?: boolean }>`
  display: flex;
  padding: 0.875rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.875rem;
  background-color: ${(p) => (p.$bgBold ? "#CBCBCB" : "#E0E0E0")};
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
`;

export const CollapseItemLabel = styled.p`
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.SM.MEDIUM.fontSize};
    font-weight: ${DISPLAY.SM.MEDIUM.fontWeight};
    line-height: ${DISPLAY.SM.MEDIUM.lineHeight};
  }
`;

export const DropdownButton = styled(BaseButton)`
  width: fit-content;
  padding-inline: 1.25rem;
  padding: 0.875rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font: inherit;
  z-index: 0;
  & > span {
    color: #fff;
    font-size: ${TEXT.MD.MEDIUM.fontSize};
    font-weight: ${TEXT.MD.MEDIUM.fontWeight};
    line-height: ${TEXT.MD.MEDIUM.lineHeight};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-inline: auto;
    padding: 0.875rem 1.25rem;
    & > span {
      font-size: ${TEXT.LG.MEDIUM.fontSize};
      font-weight: ${TEXT.LG.MEDIUM.fontWeight};
      line-height: ${TEXT.LG.MEDIUM.lineHeight};
    }
  }
`;

export const DropdownMenu = styled.div`
  display: grid;
  border-radius: 1rem;
  border: 2px solid ${(props) => props.theme.colors.neutralB700};
  box-shadow: 6px 6px 0px 0px #000;
  overflow: hidden;
  font: inherit;
  z-index: 0;
  & .ant-dropdown-menu {
    padding: 0;
  }
  & .ant-dropdown-menu-item {
    padding: 0 !important;
  }
`;

export const DropdownItem = styled.a`
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background: ${(props) => props.theme.colors.primary700};
  cursor: pointer;
  color: #fff !important;
  font-family: Poppins;
  font-size: ${TEXT.MD.MEDIUM.fontSize};
  font-weight: ${TEXT.MD.MEDIUM.fontWeight};
  line-height: ${TEXT.MD.MEDIUM.lineHeight};
`;

export const NavListDecorator = styled.div<{ $desktopNavListOpen?: boolean }>`
  padding-bottom: 0.5rem;
  color: #fff;
  border-bottom: 2px solid #cfd5e2;
  @media (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    & span {
      font-size: ${TEXT.XL.MEDIUM.fontSize};
      font-weight: ${TEXT.XL.MEDIUM.fontWeight};
      line-height: ${TEXT.XL.MEDIUM.lineHeight};
    }
  }
  @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    & span {
      font-size: ${TEXT.MD.MEDIUM.fontSize};
      font-weight: ${TEXT.MD.MEDIUM.fontWeight};
      line-height: ${TEXT.MD.MEDIUM.lineHeight};
    }
  }
`;
