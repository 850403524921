import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import mainRoutes from './mainRoutes'
import { MainLayout, PrimaryFooter, PrimaryHeader } from '../layouts'
import { ErrorPage } from '../pages'
import { Spin } from 'antd'

const AppRouter = () => {
	return (
		<Router>
			<MainLayout>
				<PrimaryHeader />
				<Suspense
					fallback={
						<Spin
							style={{
								minHeight: '75vh',
								display: 'grid',
								placeContent: 'center',
							}}
						/>
					}>
					<Routes>
						{mainRoutes.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								element={route.element}
							/>
						))}
						{/* Fallback route for 404 or unknown paths */}
						<Route
							path="*"
							element={<ErrorPage>404 Not Found!</ErrorPage>}
						/>
					</Routes>
				</Suspense>
				<PrimaryFooter />
			</MainLayout>
		</Router>
	)
}

export default AppRouter
