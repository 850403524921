import { BaseBox } from "@/styles/common/component";
import styled from "styled-components";

export const HomePageHeroTextWrapper = styled.div`
  text-align: left;
  & > * + * {
    margin-top: 2rem;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-inline: auto;
    text-align: center;
    padding-inline: 2rem;
    & > * {
      margin-inline: auto;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-inline: 10rem;
  }
`;

export const StyledHomePageIllustration = styled(BaseBox)<{ $src: string }>`
  width: 100%;
  height: 16rem;
  margin-inline: auto;
  border: 2px solid ${(p) => p.theme.colors.neutralB700};
  background-image: ${(p) => `url(${p.$src})`};
  background-color: #d3d3d3;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: normal;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 32rem;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 62rem;
    height: 46rem;
  }
`;
