import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageWrapper } from "@/app/layouts";
import styled from "styled-components";

const ErrorPageLayout = styled.section`
  display: grid;
  gap: 1rem;
  place-content: center;
  min-height: 75vh;
  min-height: 75dvh;
`;

const ErrorPage = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <PageWrapper title="Error">
      <ErrorPageLayout>
        {children}
      </ErrorPageLayout>
    </PageWrapper>
  );
};

export default ErrorPage;
