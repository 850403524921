import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { DISPLAY, TEXT } from '@/constants'
import { BaseButton } from '@/styles/common/component'

export const PrimaryHeaderWrapper = styled.header`
	padding-block: 2.5rem;
	& a {
		text-decoration: none;
	}
	& .sm-only {
		display: none;
	}
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		padding-block: 1rem;
		& .lg-only {
			display: none;
		}
		& .sm-only {
			display: block;
		}
	}
`

export const HeaderLogoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	color: ${p => p.theme.colors.neutralB700};
	font-size: ${TEXT.XL.MEDIUM.fontSize};
	font-weight: ${TEXT.XL.MEDIUM.fontWeight};
	line-height: ${TEXT.XL.MEDIUM.lineHeight};
`

export const PrimaryNavigationWrapper = styled.nav`
	overflow: hidden;
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		padding: min(30vh, 10rem) 4rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		position: fixed;
		inset: 0;
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(0.7rem);
		z-index: 999;
		visibility: hidden;
		opacity: 0;
		transition:
			opacity 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95),
			visibility 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
		&[data-visible='true'] {
			overflow: hidden;
			visibility: visible;
			opacity: 1;
		}
	}
	@media (max-width: ${p => p.theme.breakpoints.tablet}) {
		padding: min(25vh, 6rem) 1rem;
	}
`

export const PrimaryNavList = styled.ul`
	display: flex;
	gap: 2.5rem;
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		flex-direction: column;
		gap: 1rem;
	}
`

export const HamBurgerButton = styled(BaseButton)`
	width: 3rem;
	height: 3rem;
	margin: 0;
	padding: 0;
	display: none;
	background: none;
	border: none;
	border-radius: 50%;
	box-shadow: none;
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		display: block;
		z-index: 1000;
	}
`

export const FloatingHamBurgerButton = styled(BaseButton)<{
	$navListOpen: boolean
}>`
	width: 3rem;
	height: 3rem;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 1rem;
	right: 3rem;
	background: #fff;
	border: none;
	border-radius: 50%;
	box-shadow: none;
	z-index: 1000;
	outline: ${p => (p.$navListOpen ? 'none' : '1px solid gray')};
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		top: 2rem;
		right: 3rem;
		display: grid;
		place-content: center;
	}
	@media (max-width: ${p => p.theme.breakpoints.tablet}) {
		width: 2.5rem;
		height: 2.5rem;
		top: 1.5rem;
		right: 1rem;
	}
`

export const ContactButton = styled(BaseButton)`
	padding-block: 0.875rem;
	padding-inline: 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	background: #fff;
	color: ${p => p.theme.colors.primary700};
	border-radius: 1rem;
	border: 2px solid ${p => p.theme.colors.primary700};
	border-bottom: 3px solid ${p => p.theme.colors.primary700};
	@media (max-width: ${p => p.theme.breakpoints.desktop}) {
		display: none;
	}
`

export const NavItem = styled(NavLink)`
	color: ${p => p.theme.colors.neutralB500};
	font-size: ${TEXT.MD.REGULAR.fontSize};
	font-weight: ${TEXT.MD.REGULAR.fontWeight};
	line-height: ${TEXT.MD.REGULAR.lineHeight};
	&:hover {
		color: inherit;
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	&.active {
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	@media screen and (max-width: ${p => p.theme.breakpoints.desktop}) {
		position: relative;
		color: #fff;
		font-size: ${DISPLAY.XL.MEDIUM.fontSize};
		font-weight: ${DISPLAY.XL.MEDIUM.fontWeight};
		line-height: ${DISPLAY.XL.MEDIUM.lineHeight};
		letter-spacing: ${DISPLAY.XL.MEDIUM.letterSpacing};
		&:hover {
			color: ${p => p.theme.colors.secondary700};
			text-decoration: underline;
			text-underline-offset: 4px;
		}
		&.active {
			color: ${p => p.theme.colors.secondary700};
			display: grid;
			grid-template-columns: 2.5rem auto;
		}
		&.active::before {
			content: '';
			margin: auto;
			inset: 0;
			width: 0.5rem;
			height: 0.5rem;
			background: ${({ theme }) => theme.colors.secondary700};
			border-radius: 50%;
		}
	}
	@media (max-width: ${p => p.theme.breakpoints.tablet}) {
		font-size: ${DISPLAY.LG.MEDIUM.fontSize};
		font-weight: ${DISPLAY.LG.MEDIUM.fontWeight};
		line-height: ${DISPLAY.LG.MEDIUM.lineHeight};
		letter-spacing: ${DISPLAY.LG.MEDIUM.letterSpacing};
	}
`

export const DesktopNavList = styled.ul`
	padding: min(25vh, 5rem) 4rem;
	position: fixed;
	inset: 0;
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(1rem);
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	transition:
		opacity 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95),
		visibility 250ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
	&[data-visible='true'] {
		visibility: visible;
		opacity: 1;
	}
	& > * + * {
		margin-top: 2rem;
	}
	@media screen and (max-width: ${p => p.theme.breakpoints.desktop}) {
		display: none;
	}
`

export const DesktopNavItem = styled(NavLink)`
	text-decoration: none;
	position: relative;
	color: #fff;
	font-size: ${DISPLAY.XL.MEDIUM.fontSize};
	font-weight: ${DISPLAY.XL.MEDIUM.fontWeight};
	line-height: ${DISPLAY.XL.MEDIUM.lineHeight};
	letter-spacing: ${DISPLAY.XL.MEDIUM.letterSpacing};
	&:hover {
		color: ${p => p.theme.colors.secondary700};
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	&.active {
		color: ${p => p.theme.colors.secondary700};
		display: grid;
		grid-template-columns: 2.5rem auto;
	}
	&.active::before {
		content: '';
		margin: auto;
		inset: 0;
		width: 0.5rem;
		height: 0.5rem;
		background: ${({ theme }) => theme.colors.secondary700};
		border-radius: 50%;
	}
`
