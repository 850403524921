import { breakpoints, DISPLAY, TEXT } from "@/constants";
import { BaseButton } from "@/styles/common/component";
import styled from "styled-components";

export const ContactPageHeroHeading = styled.h1`
  font-size: ${DISPLAY.MD.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.MD.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.MD.SEMI_BOLD.lineHeight};
  letter-spacing: ${DISPLAY.MD.SEMI_BOLD.letterSpacing};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
    font-size: ${DISPLAY.LG.SEMI_BOLD.fontSize};
    font-weight: ${DISPLAY.LG.SEMI_BOLD.fontWeight};
    line-height: ${DISPLAY.LG.SEMI_BOLD.lineHeight};
    letter-spacing: ${DISPLAY.LG.SEMI_BOLD.letterSpacing};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    text-align: initial;
  }
`;

export const ContactPageHeroParagraph = styled.p`
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    text-align: initial;
  }
`;

export const ContactPageLayout = styled.div`
  display: grid;
  gap: 2.5rem;
  & .contactpage-form-animation {
    display: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 5rem;
    padding-inline: 4rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr;
    gap: 7.5rem;
    & .contactpage-form-animation {
      display: grid;
    }
    & > :last-child {
      order: -1;
    }
  }
`;

export const ContactPageHeroContact = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.desktop}) {
    display: grid;
    gap: 1rem;
  }
`;

export const ContactPageHeroContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${(p) => p.theme.colors.neutralB300};
  font-size: ${TEXT.XL.REGULAR.fontSize};
  font-weight: ${TEXT.XL.REGULAR.fontWeight};
  line-height: ${TEXT.XL.REGULAR.lineHeight};
`;

export const ContactPageForm = styled.form`
  padding-block: 2rem;
  padding-inline: 1rem;
  display: grid;
  gap: 1.5rem;
  border-radius: 2rem;
  border: 2px solid #000;
  background: #fff;
  box-shadow: 6px 6px 0px 0px #000;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-block: 2.5rem;
    padding-inline: 2rem;
    gap: 2rem;
  }
`;



export const ContactPageFormButton = styled(BaseButton)`
  padding-block: 0.875rem;
  padding-inline: 1rem;
  border-radius: 1rem;
  border: 2px solid #000;
  background: #d9d9d9;
  justify-self: flex-end;
  font-size: ${TEXT.MD.MEDIUM.fontSize};
  font-weight: ${TEXT.MD.MEDIUM.fontWeight};
  line-height: ${TEXT.MD.MEDIUM.lineHeight};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-inline: 1.25rem;
    font-size: ${TEXT.XL.MEDIUM.fontSize};
    font-weight: ${TEXT.XL.MEDIUM.fontWeight};
    line-height: ${TEXT.XL.MEDIUM.lineHeight};
  }
`;
