export const breakpoints = {
  // mobile: 720, // 768 should be
  minMobile: 400,
  mobile: 400,
  tablet: 600,
  desktop: 1200,
};

export const TEXT = {
  XS: {
    LIGHT: { fontSize: "0.75rem", lineHeight: "1.125rem", fontWeight: 300 },
    REGULAR: { fontSize: "0.75rem", lineHeight: "1.125rem", fontWeight: 400 },
    MEDIUM: { fontSize: "0.75rem", lineHeight: "1.125rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "0.75rem", lineHeight: "1.125rem", fontWeight: 600 },
    BOLD: { fontSize: "0.75rem", lineHeight: "1.125rem", fontWeight: 700 },
  },
  SM: {
    LIGHT: { fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 300 },
    REGULAR: { fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 400 },
    MEDIUM: { fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 600 },
    BOLD: { fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 700 },
  },
  MD: {
    LIGHT: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 300 },
    REGULAR: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 400 },
    MEDIUM: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 600 },
    BOLD: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 700 },
  },
  LG: {
    LIGHT: { fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: 300 },
    REGULAR: { fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: 400 },
    MEDIUM: { fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: 600 },
    BOLD: { fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: 700 },
  },
  XL: {
    LIGHT: { fontSize: "1.25rem", lineHeight: "1.875rem", fontWeight: 300 },
    REGULAR: { fontSize: "1.25rem", lineHeight: "1.875rem", fontWeight: 400 },
    MEDIUM: { fontSize: "1.25rem", lineHeight: "1.875rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "1.25rem", lineHeight: "1.875rem", fontWeight: 600 },
    BOLD: { fontSize: "1.25rem", lineHeight: "1.875rem", fontWeight: 700 },
  },
};

export const DISPLAY = {
  XS: {
    LIGHT: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 300 },
    REGULAR: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 400 },
    MEDIUM: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 500 },
    SEMI_BOLD: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 600 },
    BOLD: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 700 },
  },
  SM: {
    LIGHT: { fontSize: "1.875rem", lineHeight: "2.375rem", fontWeight: 300 },
    REGULAR: { fontSize: "1.875rem", lineHeight: "2.375rem", fontWeight: 400 },
    MEDIUM: { fontSize: "1.875rem", lineHeight: "2.375rem", fontWeight: 500 },
    SEMI_BOLD: {
      fontSize: "1.875rem",
      lineHeight: "2.375rem",
      fontWeight: 600,
    },
    BOLD: { fontSize: "1.875rem", lineHeight: "2.375rem", fontWeight: 700 },
  },
  MD: {
    LIGHT: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontWeight: 300,
      letterSpacing: "-2%",
    },
    REGULAR: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontWeight: 400,
      letterSpacing: "-2%",
    },
    MEDIUM: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontWeight: 500,
      letterSpacing: "-2%",
    },
    SEMI_BOLD: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontWeight: 600,
      letterSpacing: "-2%",
    },
    BOLD: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontWeight: 700,
      letterSpacing: "-2%",
    },
  },
  LG: {
    LIGHT: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 300,
      letterSpacing: "-2%",
    },
    REGULAR: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 400,
      letterSpacing: "-2%",
    },
    MEDIUM: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 500,
      letterSpacing: "-2%",
    },
    SEMI_BOLD: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 600,
      letterSpacing: "-2%",
    },
    BOLD: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 700,
      letterSpacing: "-2%",
    },
  },
  XL: {
    LIGHT: {
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      fontWeight: 300,
      letterSpacing: "-2%",
    },
    REGULAR: {
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      fontWeight: 400,
      letterSpacing: "-2%",
    },
    MEDIUM: {
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      fontWeight: 500,
      letterSpacing: "-2%",
    },
    SEMI_BOLD: {
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      fontWeight: 600,
      letterSpacing: "-2%",
    },
    BOLD: {
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      fontWeight: 700,
      letterSpacing: "-2%",
    },
  },
  "2XL": {
    LIGHT: {
      fontSize: "4.5rem",
      lineHeight: "5.625rem",
      fontWeight: 300,
      letterSpacing: "-2%",
    },
    REGULAR: {
      fontSize: "4.5rem",
      lineHeight: "5.625rem",
      fontWeight: 400,
      letterSpacing: "-2%",
    },
    MEDIUM: {
      fontSize: "4.5rem",
      lineHeight: "5.625rem",
      fontWeight: 500,
      letterSpacing: "-2%",
    },
    SEMI_BOLD: {
      fontSize: "4.5rem",
      lineHeight: "5.625rem",
      fontWeight: 600,
      letterSpacing: "-2%",
    },
    BOLD: {
      fontSize: "4.5rem",
      lineHeight: "5.625rem",
      fontWeight: 700,
      letterSpacing: "-2%",
    },
  },
};
