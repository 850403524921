import React from "react";
import styled from "styled-components";

const StyledLayout = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows:
    auto
    1fr
    auto;
`;

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

export default MainLayout;
