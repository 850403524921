import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`    
  ${reset}   

  :root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  html {
    scroll-behavior: smooth; 
    scroll-padding: 10rem;
  }
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
    display: block;
  }
  body {
    font-family : "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  } 
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 4px 0 !important;
  }
  .resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

`;

export default GlobalStyle;
