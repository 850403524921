import styled from "styled-components";
import { DISPLAY, TEXT } from "@/constants";

export const HeroHeading = styled.h1`
  max-width: 14ch;
  font-size: ${DISPLAY.LG.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.LG.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.LG.SEMI_BOLD.lineHeight};
  letter-spacing: ${DISPLAY.LG.SEMI_BOLD.letterSpacing};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.XL.SEMI_BOLD.fontSize};
    font-weight: ${DISPLAY.XL.SEMI_BOLD.fontWeight};
    line-height: ${DISPLAY.XL.SEMI_BOLD.lineHeight};
    letter-spacing: ${DISPLAY.XL.SEMI_BOLD.letterSpacing};
  }
`;
export const HeroParagraph = styled.p`
  max-width: 40ch;
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 56ch;
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 75ch;
  }
`;

export const CtaHeading = styled.h2`
  color: ${(props) => props.theme.colors.neutralB700};
  font-size: ${DISPLAY.SM.MEDIUM.fontSize};
  font-weight: ${DISPLAY.SM.MEDIUM.fontWeight};
  line-height: ${DISPLAY.SM.MEDIUM.lineHeight};
  text-align: left;
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 20ch;
    margin-inline: auto;
    font-size: ${DISPLAY.LG.MEDIUM.fontSize};
    font-weight: ${DISPLAY.LG.MEDIUM.fontWeight};
    line-height: ${DISPLAY.LG.MEDIUM.lineHeight};
    text-align: center;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 30ch;
  }
`;
export const CtaParagraph = styled.p`
  color: ${(props) => props.theme.colors.neutralB700};
  font-size: ${TEXT.MD.MEDIUM.fontSize};
  font-weight: ${TEXT.MD.MEDIUM.fontWeight};
  line-height: ${TEXT.MD.MEDIUM.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 39ch;
    margin-inline: auto;
    font-size: ${TEXT.XL.MEDIUM.fontSize};
    font-weight: ${TEXT.XL.MEDIUM.fontWeight};
    line-height: ${TEXT.XL.MEDIUM.lineHeight};
    text-align: center;
  }
`;

export const CardHeading = styled.h3`
  font-size: ${DISPLAY.XS.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.XS.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.XS.SEMI_BOLD.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.SM.SEMI_BOLD.fontSize};
    font-weight: ${DISPLAY.SM.SEMI_BOLD.fontWeight};
    line-height: ${DISPLAY.SM.SEMI_BOLD.lineHeight};
  }
`;
export const CardParagraph = styled.p`
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
`;

export const AccordionItemLabel = styled.p`
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.SM.MEDIUM.fontSize};
    font-weight: ${DISPLAY.SM.MEDIUM.fontWeight};
    line-height: ${DISPLAY.SM.MEDIUM.lineHeight};
  }
`;

export const AccordionItemHeading = styled.h4`
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${DISPLAY.SM.MEDIUM.fontSize};
    font-weight: ${DISPLAY.SM.MEDIUM.fontWeight};
    line-height: ${DISPLAY.SM.MEDIUM.lineHeight};
  }
`;

export const AccordionItemParagraph = styled.p`
  font-size: ${TEXT.MD.REGULAR.fontSize};
  font-weight: ${TEXT.MD.REGULAR.fontWeight};
  line-height: ${TEXT.MD.REGULAR.lineHeight};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${TEXT.XL.REGULAR.fontSize};
    font-weight: ${TEXT.XL.REGULAR.fontWeight};
    line-height: ${TEXT.XL.REGULAR.lineHeight};
  }
`;

export const SectionHeading = styled.h2`
  font-size: ${DISPLAY.MD.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.MD.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.MD.SEMI_BOLD.lineHeight};
  letter-spacing: ${DISPLAY.MD.SEMI_BOLD.letterSpacing};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: ${DISPLAY.XL.SEMI_BOLD.fontSize};
    font-weight: ${DISPLAY.XL.SEMI_BOLD.fontWeight};
    line-height: ${DISPLAY.XL.SEMI_BOLD.lineHeight};
    letter-spacing: ${DISPLAY.XL.SEMI_BOLD.letterSpacing};
  }
`;

export const SectionNavigatorExtraInfoHeading = styled.p`
  font-size: ${DISPLAY.XS.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.XS.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.XS.SEMI_BOLD.lineHeight};
`;

export const SectionNavigatorExtraInfoParagraph = styled.p`
  color: ${(p) => p.theme.colors.neutralB50};
  font-size: ${TEXT.XL.REGULAR.fontSize};
  font-weight: ${TEXT.XL.REGULAR.fontWeight};
  line-height: ${TEXT.XL.REGULAR.lineHeight};
`;
