import styled from 'styled-components'

/**
 * Wrapper component
 * This component provides a responsive wrapper with customizable maximum width.
 * It uses a CSS variable to control the maximum width, allowing for dynamic adjustments.
 * - The width is constrained by the `--max-width` variable (default: 1512px) minus the padding.
 * - The padding is controlled by fixed values:
 *   - Default padding is 2rem.
 *   - At `breakpoints.tablet`, padding is set to 6rem.
 *   - At `breakpoints.desktop`, padding is set to 12rem.
 */
export const Wrapper = styled.div`
	width: min(var(--max-width, 1512px), 100% - var(--padding-inline, 2rem));
	margin-inline: auto;

	@media (min-width: ${p => p.theme.breakpoints.tablet}) {
		--padding-inline: 6rem;
	}
	@media (min-width: ${p => p.theme.breakpoints.desktop}) {
		--padding-inline: 12rem;
	}
`

/**
 * FlowSpacer component
 * This component sets the top margin for every sibling element based on the viewport size.
 * Defaults to "1rem" if no specific value is provided for the respective viewport.
 */
export const FlowSpacer = styled.div<{
	$mobile?: string
	$tablet?: string
	$desktop?: string
}>`
	& > * + * {
		margin-top: ${p => p.$mobile || '1rem'};
	}

	@media (min-width: ${p => p.theme.breakpoints.tablet}) {
		& > * + * {
			margin-top: ${p => p.$tablet || '1rem'};
		}
	}
	@media (min-width: ${p => p.theme.breakpoints.desktop}) {
		& > * + * {
			margin-top: ${p => p.$desktop || '1rem'};
		}
	}
`

/**
 * HeroWrapper component
 * This component provides a responsive grid layout for the hero section.
 * It adapts its layout and spacing based on the screen size.
 * - By default, it displays a grid with a gap of 2rem between items.
 * - At `breakpoints.tablet`, the gap between grid items is increased to 2.5rem.
 * - At `breakpoints.desktop`, the layout changes to a two-column grid:
 *   - The grid has two columns with a ratio of 2fr to 3fr.
 *   - `article` elements within the grid receive additional padding of 1.5rem on the top and bottom.
 * This component is used for the hero section on all pages except the home page, which has a different style.
 */
export const HeroWrapper = styled.div`
	display: grid;
	gap: 2rem;
	@media (min-width: ${p => p.theme.breakpoints.tablet}) {
		gap: 2.5rem;
	}
	@media (min-width: ${p => p.theme.breakpoints.desktop}) {
		grid-template-columns: 2fr 3fr;
		& article {
			padding-block: 1.5rem;
		}
	}
`

export const PaddedSection = styled.section<{
	$mobile?: string
	$tablet?: string
	$desktop?: string
	$mobileBgColor?: string
	$tabletBgColor?: string
	$desktopBgColor?: string
}>`
	padding-block: ${p => p.$mobile || '2rem'};
	background-color: ${p => p.$mobileBgColor || 'transparent'};
	@media (min-width: ${p => p.theme.breakpoints.tablet}) {
		padding-block: ${p => p.$tablet || '3.5rem'};
		background-color: ${p => p.$tabletBgColor || 'transparent'};
	}
	@media (min-width: ${p => p.theme.breakpoints.desktop}) {
		padding-block: ${p => p.$desktop || '3.5rem'};
		background-color: ${p => p.$desktopBgColor || 'transparent'};
	}
`
