import { breakpoints } from "@/constants";
import { DefaultTheme } from "styled-components";

const lightThemeColors: DefaultTheme["colors"] = {
  primary50: "",
  primary100: "#e6e5ff",
  primary300: "#c3c2ff",
  primary500: "#a5a3ff",
  primary700: "#7976ff",
  primary900: "#4b47ff",
  secondary700: "#FFFF2E",
  neutralB50: "#6D6D6D",
  neutralB300: "#333333",
  neutralB500: "#292929",
  neutralB700: "#1C1C1C",
  neutralB900: "#06081A",
  neutralW300: "#F3F5F8",
  neutralW700: "#CFD5E2",
  neutralW900: "#97A2B7",
};

const darkThemeColors: DefaultTheme["colors"] = {
  primary50: "",
  primary100: "",
  primary300: "",
  primary500: "",
  primary700: "",
  primary900: "",
  secondary700: "",
  neutralB50: "",
  neutralB300: "",
  neutralB500: "",
  neutralB700: "",
  neutralB900: "",
  neutralW300: "",
  neutralW700: "",
  neutralW900: "",
};

const themeColor = {
  light: lightThemeColors,
  dark: darkThemeColors,
};

const themeBreakpoints: DefaultTheme["breakpoints"] = {
  mobile: `${breakpoints.mobile}px`,
  tablet: `${breakpoints.tablet}px`,
  desktop: `${breakpoints.desktop}px`,
};

export { themeColor, themeBreakpoints };
