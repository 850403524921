import React from "react";
import styled from "styled-components";

const VerticalSpacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--mobile-gap, 1rem);

  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: var(--tablet-gap, 1rem);
  }
  @media (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    gap: var(--desktop-gap, 1rem);
  }
`;

const CVerticalSpacer = ({
  mobile,
  tablet,
  desktop,
  children,
}: {
  mobile?: string;
  tablet?: string;
  desktop?: string;
  children: React.ReactNode;
}) => {
  return (
    <VerticalSpacer
      style={
        {
          "--mobile-gap": mobile,
          "--tablet-gap": tablet,
          "--desktop-gap": desktop,
        } as React.CSSProperties
      }
    >
      {children}
    </VerticalSpacer>
  );
};

export default CVerticalSpacer;
