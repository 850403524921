import { TEXT } from "@/constants";
import styled from "styled-components";

export const TabBarLayout = styled.div`
  margin-inline: auto;
  position: sticky;
`;

export const TabBarList = styled.ul`
  position: sticky;
  max-width: fit-content;
  margin-inline: auto;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  gap: 1rem;
  list-style: none;
  border-radius: 0.875rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

export const TabBarItem = styled.a`
  padding-block: 0.625rem;
  padding-inline: 1rem;
  display: block;
  text-decoration: none;
  &[aria-selected="true"] {
    border-radius: 0.875rem;
    background: #e2e2e2;
  }
`;

export const TabBarLabel = styled.p`
  color: #000;
  font-size: ${TEXT.MD.MEDIUM.fontSize};
  font-weight: ${TEXT.MD.MEDIUM.fontWeight};
  line-height: ${TEXT.MD.MEDIUM.lineHeight};
`;

export const TabPanelLayout = styled.div`
  padding-top: 7rem;
`;
