import { DISPLAY, TEXT } from "@/constants";
import styled from "styled-components";

export const SectionNavigatorLayout = styled.div`
  max-height: 100%;
  display: grid;
  grid-template-columns: 26ch 1fr 26ch;
  gap: 4.5rem;
  overflow: hidden;
`;

export const NavigatorListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const NavigatorTitle = styled.p`
  font-size: ${DISPLAY.XS.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.XS.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.XS.SEMI_BOLD.lineHeight};
`;

export const NavigatorList = styled.ul`
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  & .active-line {
    width: 2px;
    position: absolute;
    left: 0;
    background-color: ${(p) => p.theme.colors.neutralB700};
    transition:
      width cubic-bezier(0.23, 1, 0.32, 1) 350ms,
      transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  }
`;

export const AnchorLink = styled.a`
  padding-left: 0.5rem;
  display: block;
  color: ${(p) => p.theme.colors.neutralB50};
  font-size: ${TEXT.XL.MEDIUM.fontSize};
  font-weight: ${TEXT.XL.MEDIUM.fontWeight};
  line-height: ${TEXT.XL.MEDIUM.lineHeight};
  text-decoration: none;
`;

export const ContentLayout = styled.div`
  display: grid;
  gap: 2rem;
`;

export const ContentTitle = styled.p`
  margin-bottom: 2rem;
  color: #000;
  font-size: ${DISPLAY.XL.SEMI_BOLD.fontSize};
  font-weight: ${DISPLAY.XL.SEMI_BOLD.fontWeight};
  line-height: ${DISPLAY.XL.SEMI_BOLD.lineHeight};
  letter-spacing: ${DISPLAY.XL.SEMI_BOLD.letterSpacing};
`;

export const SectionsContainer = styled.div`
  max-height: 696px;
  display: grid;
  gap: 5rem;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ExtraInfoLayout = styled.div``;
