import styled from "styled-components";
import { DISPLAY, TEXT } from "@/constants";
import { BaseBox } from "@/styles/common/component";

export const ProductPageCtaWrapper = styled(BaseBox)`
  padding-block: 1rem;
  padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-block: 4rem;
    padding-inline: 2.5rem;
    gap: 4rem;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 4rem;
    padding-block: 10rem;
    padding-inline: 4rem;
  }
`;

export const ProductPageCtaHeading = styled.h2`
  color: ${(props) => props.theme.colors.neutralB700};
  font-size: ${DISPLAY.SM.MEDIUM.fontSize};
  font-weight: ${DISPLAY.SM.MEDIUM.fontWeight};
  line-height: ${DISPLAY.SM.MEDIUM.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 20ch;
    margin-inline: auto;
    font-size: ${DISPLAY.LG.MEDIUM.fontSize};
    font-weight: ${DISPLAY.LG.MEDIUM.fontWeight};
    line-height: ${DISPLAY.LG.MEDIUM.lineHeight};
    text-align: center;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 30ch;
  }
`;

export const ProductPageCtaParagraph = styled.p`
  color: ${(props) => props.theme.colors.neutralB700};
  font-size: ${TEXT.MD.MEDIUM.fontSize};
  font-weight: ${TEXT.MD.MEDIUM.fontWeight};
  line-height: ${TEXT.MD.MEDIUM.lineHeight};
  @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 39ch;
    margin-inline: auto;
    font-size: ${TEXT.XL.MEDIUM.fontSize};
    font-weight: ${TEXT.XL.MEDIUM.fontWeight};
    line-height: ${TEXT.XL.MEDIUM.lineHeight};
    text-align: center;
  }
`;
