import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const appNotStore = createSlice({
  name: "appnotstore",
  initialState: initialState,
  reducers: {},
});
export const {} = appNotStore.actions;

export default appNotStore.reducer;
